<template>
    <b-card class="notifications p-2">
        <h1 class="text-secondary font">Obvestila</h1>

        <div class="mt-2 bigger-font" v-for="(item,id) in data" :key="'notification_'+ id">
            <b-button class="button-notification-toggle px-2 py-1 d-flex align-items-center" block v-b-toggle="'notification_item_' + id" variant="primary" @click="markNotificationAsRead(item)">
                <div class="bigger-font row w-100 d-flex align-items-center">
                    <div class="col-md-2 col-6" :class="{'notificationNotRead': !item.has_been_read}">
                        {{dayjs(item.datum_vnosa).format("DD.MM.YYYY")}}
                        <div style="border-right: 2px solid #B2B2B2; position: absolute; top: -5px; right: 5px; width: 0px; height: 25px;">
                        </div>
                    </div>
                    <div class="col-md-2 col-6 d-md-block d-none text-center" :class="{'notificationNotRead': !item.has_been_read}">
                        {{item.manager}}
                    </div>
                    <div class="col-md-5 col-6 text-left" :class="{'notificationNotRead': !item.has_been_read}">
                        {{item.message.subject}}
                    </div>
                    <div class="col-md-3 col-12 mt-2 mt-md-0 d-md-flex d-none justify-content-md-end justify-content-center align-items-center">
                        <!-- <div class="mr-2 mr-md-0">
                            prevzemi dokument
                        </div> -->
                        <img style="width: 40px; height: 40px;" :src="require('@/assets/svg/pdf_icon.svg')"/>
                    </div>
                </div>
            </b-button>


            <b-collapse class="p-1" :id="'notification_item_' + id" style="margin-top: 10px; background: #F3F3F3 !important;">
                <div class="bigger-font" v-html="item.message.html" />
                <div class="row mt-3" v-if="item.pdf && item.pdf.length > 0">
                    <div class="col-md-4 col-12" v-for="(item1,id1) of item.pdf" :key="'pdf_' + id1" style="max-height: 300px; overflow-y: hidden; position:relative;">
                        <div v-if="item.message && item.message.attachments">
                            <a class="pdf-preview-parent" :href="'/api/iiportal-user/v1/attachements/' + item.message.attachments[id1]" target="_blank">
                                <pdf :src="item1.src"
                                >
                                </pdf>
                                <img class="pdf-preview" :src="require('@/assets/svg/pdf_preview.svg')"/>
                            </a>
                        </div>
                       
                    </div>
                </div>

                <div class="bigger-font d-flex mt-2 align-items-center">
                    <div class="mr-1" style="color: #62B014; font-weight: bold; cursor: pointer;" v-on:click="downloadPdfs(item)">
                        Prevzemi dokument
                        <img style="margin-left: 10px; width: 20px; height: 20px;" :src="require('@/assets/svg/download_icon.svg')"/>
                    </div>
                </div>
            </b-collapse>
        </div>

        <div class="text-center" v-if="!data || (data && data.length == 0)">
            <h4>Vaš nabiralnik je prazen</h4>
        </div>
       
        <div class="text-center mt-2">
            <pagination v-if="pagination" v-model="pagination" class="mt-1"/>
        </div>
    </b-card>
</template>

<script>
    // import flatPickr from 'vue-flatpickr-component'
    import 'flatpickr/dist/flatpickr.css'
    import {Slovenian} from 'flatpickr/dist/l10n/sl.js'
    import {BCollapse, VBToggle, BCard, BButton} from 'bootstrap-vue'
    import Pagination from '@/views/Components/Pagination'
    import Vue from 'vue'
    import pdf from 'vue-pdf'
    import {FileMimeType} from '@/libs/mimeTypes'

    export default {
        components: {
            pdf,
            BButton,
            BCollapse,
            Pagination,
            BCard
        },
        data() {
            return {
                pdfs: [],
                from: '',
                flatpickrConfig: { altInput: true, dateFormat: 'Z', altFormat: 'd. m. Y', wrap: true, locale: Slovenian},
                data: [],
                pagination: {
                    current_page: 1,
                    next_page: 1,
                    per_page: 6,
                    prev_page: 0,
                    total_items: 0,
                    total_pages: 0
                }
            }
        },
        methods: {
            async markNotificationAsRead(item) {
                try {
                    await this.$http.put(`/api/iiportal-user/v1/e_manager/obvestila/set_read/${item.id}`)
                    await this.$store.dispatch('unreadNotification/updateUnreadNotification')
                    item.has_been_read = true
                } catch (error) {
                    this.$printError(`Pri nalaganju pdf je prišlo do napake!\n${error.message}`)
                }
            },
            downloadPdfs(item) {
                item.has_been_read = true
                this.$http({
                    url: `/api/iiportal-user/v1/e_manager/obvestila/attachements/${ item.id}`, //your url
                    method: 'GET',
                    responseType: 'blob' // important
                }).then((response) => {
                    let fileType = 'zip'
                    fileType = FileMimeType[response.data.type]

                    const url = window.URL.createObjectURL(new Blob([response.data]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', `Obvestilo.${fileType}`) //or any other extension
                    document.body.appendChild(link)
                    link.click()
                })
            },
            async loadData() {
                const res = await this.$http.get(`/api/iiportal-user/v1/e_manager/obvestila?page=${this.pagination.current_page}&perPage=${this.pagination.per_page}`)
                this.data = res.data.notifications
                this.pagination = res.data.pagination

                for (const id in this.data) {
                    const notification = this.data[id]

                    this.pdfs.push([])

                    const tmpPdfs = []

                    if (notification && notification.message) {
                        for (const id1 in notification.message.attachments) {
                            const attachment = notification.message.attachments[id1]

                            const single_pdf = await this.$http.get(`/api/iiportal-user/v1/attachements/${ attachment}`, {responseType: 'blob'})

                            const blob = new Blob([single_pdf.data])
                            const objectUrl = URL.createObjectURL(blob)
                            const pdf_src = await pdf.createLoadingTask(objectUrl)

                            if (single_pdf && single_pdf.data && single_pdf.data.type === 'application/pdf') {
                                tmpPdfs.push({src: pdf_src, objectUrl })
                            }

                        }
                    }

                    Vue.set(this.data[id], 'pdf', tmpPdfs)

                }

            }
        },
        async mounted() {
            await this.loadData()
        },
        created() {
            Vue.directive('b-toggle', VBToggle)
        },
        watch: {
            async 'pagination.current_page'() {
                await this.loadData()
            }
        }

    }
</script>

<style scoped>
  .notificationNotRead{
    font-weight: bold;
  }
  .button-notification-toggle{
    background: #F3F3F3 !important;
    color: black !important;
    border: none !important;
  }

  .pdf-preview{
    opacity: 0;
    position: absolute; 
    width: 100px; 
    height: 100px; 
    z-index: 1100; 
    top: 30%; 
    left: calc(50% - 50px);
  }

  .pdf-preview-parent:hover > .pdf-preview{
    opacity: 1;
  }

  .pdf-preview-parent:hover{
    cursor: pointer;
  }

</style>

<style>
.bigger-font{
  font-size: 17px !important;
}

.bigger-font span, .bigger-font p{
  font-size: 17px !important;
}
.notifications .font {
  font-weight: bold;
}
.notifications .table thead tr th {
  white-space: pre-wrap;
  text-align:left;
}
.notifications .append-button {
  background-color: #009FD4;
  border: none;
  color: white
}
.notifications .form-control {
  border: none;
}
.notifications .dropdown .btn, .regular-billing .dropdown .dropdown-toggle {
  border-radius: 0;
  border: none;
}
.notifications .dropdown .btn {
  background-color: #E8E7E6 !important;
  color:black !important;
  box-shadow: none !important;
}
.notifications .dropdown .dropdown-toggle {
  background-color: #009FD4 !important;
  color:black;
}
.notifications .badge{
  border-radius: 0;
}
.notifications .card {
  border-radius: 0;
  box-shadow: none;
  border: 1px solid #ADACAB !important;
  background-color: #E8E7E6;
}
.notifications .card-body {
  padding: 0;
}
.notifications .card-footer, .notifications .card-text {
  padding: 0 1.5rem 1.5rem 1.5rem !important;
}
</style>
